<template>
  <div class="card w-100 entity-assesment-summary" style="height: 380px">
    <div class="card-header pb-75">
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center"
      >
        <h4 class="mb-0 text-center">Entity Assessment Summary</h4>
      </div>
    </div>
    <div class="card-body pt-0 implemnetation-wrapper" style="overflow:auto">
      <card-percentage-table :listData="listData" :chartSeries="chartSeries" />
    </div>
  </div>
</template>

<script>
import CardPercentageTable from "../../../components/CardPercentageTable.vue";
export default {
  components: { CardPercentageTable },
  props:{
    summaryData:{
      type:Object,
      required:false
    },
    
  },
  data() {
    return {
      // chartSeries: [54.4, 67.1, 14.6, 4.2, 92.4, 72.4],
      // listData: [
      //   {
      //     name: "Google Chrome",
      //     usage: "54.4%",
      //   },
      //   {
      //     name: "Mozila Firefox",
      //     usage: "67.1%",
      //   },
      //   {
      //     name: "Apple Safari",
      //     usage: "14.6%",
      //   },
      //   {
      //     name: "Internet Explorer",
      //     usage: "4.2%",
      //   },
      //   {
      //     name: "Opera Mini",
      //     usage: "92.4%",
      //   },
      //   {
      //     name: "Opera Mini",
      //     usage: "72.4%",
      //   },
       
      // ],
    };
  },
  computed:{
    listData(){
      if(this.summaryData&&typeof(this.summaryData)==='object'){

        const data=Object.keys(this.summaryData).map(item=>{
          return {
            name:this.summaryData[item].title,
            usage:this.summaryData[item].compliance,
            is_number:this.summaryData[item].is_number
          }
        })
        if(this.summaryData?.annex_control_family_group?.not_applicable_controls!==undefined){
          data.push({
            name:'Not Applicable Control Annexure Controls',
            usage:this.summaryData?.annex_control_family_group?.not_applicable_controls
          })
        }
        if(this.summaryData?.over_all_compliance?.not_applicable_controls!==undefined){
          data.push({
            name:'Not Applicable Control',
            usage:this.summaryData?.over_all_compliance?.not_applicable_controls
          })
        }
        if(this.summaryData?.over_all_compliance?.not_applicable_sub_controls!==undefined){
          data.push({
            name:`Not Applicable Sub Control (Out of ${this.summaryData?.over_all_compliance?.total_sub_controls_count})`,
            usage:this.summaryData?.over_all_compliance?.not_applicable_sub_controls
          })
        }
        return data
      }
      else return []
    },
    chartSeries(){
      if(this.summaryData&&typeof(this.summaryData)==='object'){

        const data=Object.values(this.summaryData).map(item=>item.compliance)
        if(this.summaryData?.over_all_compliance?.not_applicable_controls!==undefined){
          data.push(
            this.summaryData?.over_all_compliance?.not_applicable_controls
          )
        }
        return data
      }
    else return []
    }
  }
};
</script>

<style lang="scss">
.entity-assesment-summary{

.implemnetation-wrapper::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.implemnetation-wrapper{
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
}
</style>
