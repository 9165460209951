<template>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="dark"
      class="w-100"
    >
      <div class="card w-100" style="min-height: 420px;">
        <div class="card-header d-flex flex-column align-items-center justify-content-center pb-0">
          <h4 class="mb-1">{{ title }}</h4>
        </div>
  
        <div class="card-body d-flex align-items-center justify-content-center w-100">
          <b-table
            v-if="!isEmpty"
            :items="tableItems"
            :fields="tableFields"
            class="family-table"
            :sticky-header="'550px'"
            head-variant="light"
            bordered
            responsive
          >
            <!-- <template v-for="field in tableFields" v-slot:[`cell(${field.key})`]="data">
              <span key="">{{ data.item[field.key] }}</span>
            </template> -->
          </b-table>
          <div
            v-else
            class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <feather-icon icon="MehIcon" size="36" />
            <p class="mt-2 text-center w-75 text-muted">
              It seems like there are no data at the moment.
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </template>
  
  <script>
  import { BTable, BOverlay } from 'bootstrap-vue';
  
  export default {
    components: {
      BTable,
      BOverlay
    },
    props: {
      title:{
        type:String,
        required:true
      },
      items: {
        type: Array,
        required: true
      },
      fields: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        showOverlay: false
      };
    },
    computed: {
      tableItems() {
        return this.items;
      },
      tableFields() {
        return this.fields;
      },
      isEmpty() {
        return !this.items.length;
      }
    }
  };
  </script>
  
  <style scoped>
  .family-table::-webkit-scrollbar {
  display: none;
}
.family-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
  </style>
