<template>
    <div class="card w-100 entity-assesment-summary" style="height: 380px">
      <div class="card-header pb-75">
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center"
        >
          <h4 class="mb-0 text-center">Standard Details</h4>
        </div>
      </div>
      <div class="card-body pt-0 implemnetation-wrapper" style="overflow:auto">
        <div  class="d-flex flex-row align-items-center pt-1  px-1">
          <div class="flex-grow-1" style="width: 55%;">
          <p>Name</p>
        </div>
  
        <div :class="skin === 'dark' ? 'usage-text' : ''" class="d-flex justify-content-center align-items-center" style="width: 20%;">
          <span>{{ summaryData.name }}</span>
        </div>
        </div>
        <div  class="d-flex flex-row align-items-center pt-1  px-1">
          <div class="flex-grow-1" style="width: 55%;">
          <p>Description</p>
        </div>
  
        <div :class="skin === 'dark' ? 'usage-text' : ''" class="d-flex justify-content-center align-items-center" style="width: 20%;">
          <span>{{ summaryData.name }}</span>
        </div>
        </div>
        <div  class="d-flex flex-row align-items-center pt-1  px-1">
          <div class="flex-grow-1" style="width: 55%;">
          <p>Countries</p>
        </div>
  
        <div v-if="countryList!=='Global'" :class="skin === 'dark' ? 'usage-text' : ''" class="d-flex justify-content-center align-items-center" style="width: 20%;">
          <span>{{ countryList }}</span>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center" style="width: 20%;">
          <b-badge variant="success">Global</b-badge>
        </div>
        </div>
        <div v-if="industryList" class="d-flex flex-row align-items-center pt-1  px-1">
          <div class="flex-grow-1" style="width: 55%;">
          <p>Industries</p>
        </div>
  
        <div  :class="skin === 'dark' ? 'usage-text' : ''" class="d-flex justify-content-center align-items-center" style="width: 20%;">
          <span>{{ industryList }}</span>
        </div>
  
        </div>
        <div  class="d-flex flex-row align-items-center pt-1  px-1">
          <div class="flex-grow-1" style="width: 55%;">
          <p>Version</p>
        </div>
  
        <div :class="skin === 'dark' ? 'usage-text' : ''" class="d-flex justify-content-center align-items-center" style="width: 20%;">
          <span>{{ summaryData.int_version }}</span>
        </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { BBadge } from "bootstrap-vue";
  import CardPercentageTable from "../../../components/CardPercentageTable.vue";
  export default {
    components: { CardPercentageTable,
      BBadge
  
     },
    props:{
      summaryData:{
        type:Object,
        required:false
      },
      
    },
    data() {
      return {
        // chartSeries: [54.4, 67.1, 14.6, 4.2, 92.4, 72.4],
        // listData: [
        //   {
        //     name: "Google Chrome",
        //     usage: "54.4%",
        //   },
        //   {
        //     name: "Mozila Firefox",
        //     usage: "67.1%",
        //   },
        //   {
        //     name: "Apple Safari",
        //     usage: "14.6%",
        //   },
        //   {
        //     name: "Internet Explorer",
        //     usage: "4.2%",
        //   },
        //   {
        //     name: "Opera Mini",
        //     usage: "92.4%",
        //   },
        //   {
        //     name: "Opera Mini",
        //     usage: "72.4%",
        //   },
         
        // ],
      };
    },
    computed:{
      countryList(){
        return  this.summaryData.countries && this.summaryData.countries.length > 0 
          ? this.summaryData.countries.map(country=>country.name).join(', ') 
          : 'Global';
      },
      industrylist(){
        return  this.summaryData.industries && this.summaryData.industries.length > 0 
          ? this.summaryData.industries.map(industry=>industry.name).join(', ') 
          : null;
      }
    }
  };
  </script>
  
  <style lang="scss">
  .entity-assesment-summary{
  
  .implemnetation-wrapper::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
  }
  .implemnetation-wrapper{
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }
  }
  </style>
  