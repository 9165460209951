<template>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="dark"
      class="w-100"
    >
      <div class="card w-100" style="height: 420px">
        <div class="card-header mx-auto">
          <h4 class="mb-0 text-center">
            Control Applicability
          </h4>
        </div>
  
        <div class="card-body position-relative" style="height: 275px">
          <radial-chart
            :series="series"
            :legend="legend"
            @chart-click="handleChartClick"
            v-if="totalUsagePercent > 0"
          />
          <div
            v-else
            class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <feather-icon icon="MehIcon" size="36" />
            <p class="mt-2 text-center w-75 text-muted">
              It seems like there are no data at the moment.
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </template>
  
  <script>
  import { BOverlay } from "bootstrap-vue";
  import RadialChart from "../../../components/RadialChart.vue";
  import ResponseMixins from "@/mixins/ResponseMixins";
  
  const chartColors = {
      primaryColorShade: "#836AF9",
      yellowColor: "#ffe800",
      successColorShade: "#28dac6",
      warningColorShade: "#ffe802",
      warningLightColor: "#FDAC34",
      infoColorShade: "#299AFF",
      greyColor: "#4F5D70",
      blueColor: "#2c9aff",
      blueLightColor: "#84D0FF",
      greyLightColor: "#EDF1F4",
      tooltipShadow: "rgba(0, 0, 0, 0.25)",
      lineChartPrimary: "#666ee8",
      lineChartDanger: "#ff4961",
      labelColor: "#6e6b7b",
      grid_line_color: "rgba(200, 200, 200, 0.2)",
    };
    const bgColors = [
      chartColors.warningColorShade,
      chartColors.primaryColorShade,
      chartColors.infoColorShade,
      chartColors.greyColor,
      chartColors.successColorShade,
    ];
  
  export default {
    mixins: [ResponseMixins],
    components: {
      BOverlay,
      RadialChart,
    },
    props: {
      complianceData: {
        type: Array,
        required: false,
      },
    },
    data() {
      return {
        totalUsagePercent: 0,
        showOverlay: false,
  
        legend: {
          orient: "vertical",
          left: 0,
          top: "middle",
          formatter: function (name) {
            const maxLength = 25;
            if (name.length > maxLength) {
              return name.substring(0, maxLength) + "...";
            }
            return name;
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["0%", "75%"],
            center: ["60%", "50%"],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
  
    watch: {
      complianceData: {
        handler(newVal) {
          if (newVal && newVal.length === 2) {
            this.updateChartData(newVal);
          }
        },
        deep: true,
      },
    },
  
    mounted() {
      this.load();
    },
  
    methods: {
      load() {
        if (this.complianceData && this.complianceData.length === 2) {
          this.updateChartData(this.complianceData);
        }
      },
  
      updateChartData(data) {
        const [applicable, nonApplicable] = data;
  
        this.totalUsagePercent = applicable + nonApplicable;
  
        const newSeriesData = [
          {
            value: applicable,
            name: "Applicable Controls",
            itemStyle: { color: bgColors[0] },
          },
          {
            value: nonApplicable,
            name: "Non Applicable Controls",
            itemStyle: { color: bgColors[1] },
          },
        ];
        this.$set(this.series[0], "data", newSeriesData);
      },
  
      handleChartClick(section) {
        // Implement chart click handler if needed
      },
  
      truncate(input) {
        if (input.length > 40) {
          return input.substring(0, 40) + "...";
        }
        return input;
      },
    },
  };
  </script>
  
  <style scoped>
  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  </style>
  