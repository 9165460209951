<template>
  <e-charts
    ref="barChart"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
    @click="handleChartClick"
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/bar";
import theme from "@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
    dontMax:{
      type:Boolean,
      required:false,
      default:false
    }
  },
  computed: {
      option()
      {

        return {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            left: 0,
          },
          grid: this.optionData.grid,
          xAxis: [
            {
              ...this.optionData.xAxis,
              max: !this.dontMax?100:null, 
            },
          ],
          yAxis: [
            {
              ...this.optionData.yAxis,
              axisLabel: {
                // width: 10,
                overflow: "truncate",
                formatter: function (value) {
                  return value.length > 6 ? value.slice(0, 6) + "..." : value;
                },
                ...this.optionData.yAxis.axisLabel,
               
              },
            },
          ],
          series: this.optionData.series,
        }
      } 

  },

  watch: {
    // optionData: {
    //   handler(newVal) {
    //     this.option.grid = newVal.grid;
    //     this.option.xAxis = [
    //       {
    //         ...newVal.xAxis,
    //         max: 100, // Make sure to update the max value here too if it changes dynamically
    //       },
    //     ];
    //     this.option.yAxis = {
    //       ...newVal.yAxis,
    //       axisLabel: {
    //         ...newVal.yAxis.axisLabel,
    //         formatter: function (value) {
    //           return value.length > 6 ? value.slice(0, 6) + "..." : value;
    //         },
    //         width: 10,
    //         overflow: "truncate",
    //       },
    //     };
    //     this.option.series = newVal.series;
    //     this.updateChart();
    //   },
    //   deep: true, // This ensures deep watching for changes in nested objects
    // },
  },

  methods: {
    updateChart() {
      this.$refs.barChart.setOption(this.option);
    },

    handleChartClick(event) {
      this.$emit("chart-click", event.name);
    },
  },
};
</script>
