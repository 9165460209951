<template>
  <div class="card" style="height: 50vh">
    <div class="card-body d-flex flex-column align-items-center justify-content-center">
      <feather-icon icon="DatabaseIcon" class="mb-1 text-secondary" size="32" />
      <h3>No Data Found</h3>
      <p>Please adjust your filters or try a different search term.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoDataFound",
};
</script>

<style scoped>
</style>