

export default {
    methods: {
        getStandardAssessments(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/standards/assessments/get-primary`,
                    params: params,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },

        updateStandardAssessmentControl(data) {
            console.log("Data: ", data);
            return new Promise((resolve, reject) => {
              const formData = new FormData();
              Object.keys(data).forEach((key) => {
                if (key == "standard_assessment_control_ids") {
                  data[key].forEach((id, i) => {
                    formData.append(`standard_assessment_control_ids[${i}]`, id);
                  });
                } else {
                  formData.append(key, data[key]);
                }
              });
      
              const options = {
                method: "POST",
                url: `${process.env.VUE_APP_BASEURL}/standards/assessments/update-controls`,
                data: formData,
              };
              this.$http(options)
                .then((res) => {
                  resolve(res);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          },

    }
}