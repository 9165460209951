<template>
    <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
    >

        <control-dashboard v-if="Object.keys(data).length" :updatedDashboardData="data"/>
        <template v-else>
            <no-data-found/>
        </template>
    </b-overlay>

    
</template>
<script>
import ResponseMixins from '@/mixins/ResponseMixins';
import ControlDashboard from './sections/ControlDashboard.vue';
import { BOverlay } from 'bootstrap-vue';
import NoDataFound from "./components/NoDataFound.vue";


export default {
    props:{
        standard_id:{
            type:String,
            required:false,
            default:null
        }
    },
    data(){
        return {
            data:{},
            showOverlay:false
        }
    },
    mounted(){
        this.getData()
    },
    mixins:[ResponseMixins],
    methods:{
       async getData(){
        const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/standards/assessments/dashbord/summary`,
                    params:{mapped_standard_id:this.standard_id},
                };
        try {
            this.showOverlay=true
            const res=await this.$http(
                    options
            )
            this.data=res.data.data
            this.showOverlay=false
        } catch (error) {
            this.handleError(error)
            this.showOverlay=false
        }
       } 
    },
    watch:{
        standard_id(){
            this.getData()
        }
    },
    components:{
        ControlDashboard,
        BOverlay,
        NoDataFound
    }
    
}
</script>
