<template>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="dark"
      class="w-100"
    >
      <div class="card w-100" style="height:420px">
        <div
          class="card-header d-flex flex-column align-items-center justify-content-center pb-0"
        >
          <h4 class="mb-0">
            Control Applicability
          </h4>
        </div>
  
        <div
          class="card-body d-flex align-items-center justify-content-center w-100"
          style="height: 400px"
        >
          <horizontal-bar-chart
            style="width: 100%"
            :optionData="options"
            @chart-click="handleChartClick"
            :dontMax="true"
            v-if="!isEmpty"
          />
          <div
            v-else
            class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <feather-icon icon="MehIcon" size="36" />
            <p class="mt-2 text-center w-75 text-muted">
              It seems like there are no data at the moment.
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </template>
  
  <script>
  import { BOverlay } from "bootstrap-vue";
  import ResponseMixins from "@/mixins/ResponseMixins";
  import HorizontalBarChart from "../../../components/HorizontalBarChart.vue";
  
  export default {
    components: { BOverlay, HorizontalBarChart },
    mixins: [ResponseMixins],
  
    props: {
      seriesData: {
        type: Array,
        required: true,
      },
    },
  
    computed: {
      labels() {
        return ["Applicable Controls", "Non Applicable Controls"];
      },
      isEmpty() {
        return !this.seriesData.some(item => item > 0);
      },
      options() {
        return {
          indexAxis: "y",
          title: {
            text: "Test",
            x: "center",
          },
          grid: {
            left: 0,
            right: 20,
            top: 100,
            bottom: 70,
            containLabel: true,
          },
          xAxis: [
            {
              type: "value",
            },
          ],
          yAxis: {
            type: "category",
            data: this.labels,
            splitLine: { show: false },
          },
          series: [
            {
              type: "bar",
              barWidth: 18,
              data: this.seriesData,
              itemStyle: {
                barBorderRadius: [0, 10, 10, 0],
              },
            },
          ],
        };
      },
    },
  
    data() {
      return {
        showOverlay: false,
      };
    },
  
    methods: {
      handleChartClick(val) {
        const section = val.toLowerCase();
        let value = null;
        if (section === "rejected") {
          value = 4;
        } else if (section === "approved") {
          value = 3;
        } else if (section === "uploaded") {
          value = 2;
        } else if (section === "requested") {
          value = 1;
        }
        if (value !== null) {
          // this.$router.push(`/responses?evidence_status=${value}`);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  </style>
