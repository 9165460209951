<template>
    <div class="d-flex flex-column w-100" style="margin: 0px;">
        <div class="ml-auto" style="width:20%;margin-bottom: -15px;margin-top: 0px;">
            <b-form-select v-model="selectedStandard" :options="standardOptions" />
        </div>

        <b-tabs nav-wrapper-class=" mt-0" lazy>
          <b-tab title="General">
            <review-controls :standard_id="selectedStandard" />
          </b-tab>
          <b-tab :title="`${selectedStandardName} Summary`">
            <management-summary :standard_id="selectedStandard"/>
          </b-tab>
        </b-tabs>
    </div>
  </template>
  
  <script>
  import { BFormSelect, BTab, BTabs } from 'bootstrap-vue';
  import ReviewControls from './ReviewControls.vue';
  import ManagementSummary from './ManagementSummary.vue';
  import StandardMixins from '@/mixins/StandardMixins';
  
  export default {
    data() {
      return {
        standards: [],
        selectedStandard: null,
      };
    },
    computed: {
      standardOptions() {
        const options = this.standards.filter(item=>!item.is_primary).map(item => {
          return {
            value: item._id,
            text: item.name,
          };
        });
        options.unshift({
          value: null,
          text: "Select Standard",
        });
        return options;
      },
      selectedStandardName(){
        if(this.standards){

          let selected
          if(this.selectedStandard){
            selected=this.standards.find(item=>item._id===this.selectedStandard)
            
          }else{
            selected=this.standards.find(item=>item.is_primary)
          }
          return selected.name
        }else return ''
      }
    },
    mixins: [StandardMixins],
    components: {
      BTabs,
      BTab,
      BFormSelect,
      ReviewControls,
      ManagementSummary,
    },
    mounted() {
      this.getStandardData();
    },
    methods: {
      async getStandardData() {
        const res = await this.getStandards({ dont_paginate: true });
        this.standards = res.data.data;
        console.log(this.standards)
      },
    },
  };
  </script>
  
  <style scoped>
  .d-flex {
    display: flex;
  }
  </style>
  
