<template>
  <div :class="['card-browser-states', skin === 'dark' ? 'dark-card' : '']" style="margin-top: 10px;">
    <div v-for="(browser, index) in listData" :key="browser.browserImg" class="d-flex flex-row align-items-center pt-1  px-1">
      <div class="flex-grow-1" style="width: 55%;">
        <p>{{ browser.name }}</p>
      </div>
      
      <div class="d-flex justify-content-center align-items-center" style="width: 25%;">
        <progress-badge-2 v-if="chartData[index]&&chartData[index].options&&chartData[index].series&&browser.name!=='Not Applicable Control'&&browser.name!=='Not Applicable Control Annexure Controls'&&!browser.name.startsWith('Not Applicable Sub Control')&&!browser.is_number"
        :value="browser.usage"
        :min="0"
        :max="100"
        :title="chart.title"
        :colorsReversed="true"
        :hideValue="true"
        />
      </div>
      <div :class="skin === 'dark' ? 'usage-text' : ''" class="d-flex justify-content-center align-items-center" style="width: 20%;">
        <span>{{ browser.usage }}{{browser.name!=='Not Applicable Control' && browser.name!=='Not Applicable Control Annexure Controls'&&!browser.name.startsWith('Not Applicable Sub Control')&&!browser.is_number?'%':''}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BImg,
} from "bootstrap-vue";
import ProgressBadge2 from "../../../../components/ProgressBadge.vue"
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BImg,
    VueApexCharts,
    ProgressBadge2
  },
  props: {
    listData: {
      type: Array,
      required: true,
    },

    chartSeries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartData: [],
      chartClone: {},
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],

      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
  computed:{
    skin(){
      return this.$store.state.appConfig.layout.skin
    }
  },
  created() {
    for (let i = 0; i < this.listData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart));
      chartClone.options.colors[0] = this.mapColorToValue(this.chartSeries[i]);
      chartClone.series[0] = this.chartSeries[i];
      this.chartData.push(chartClone);
    }
  },

  methods: {
    mapColorToValue(value) {
      // $themeColors.primary,
      // $themeColors.warning,
      // $themeColors.secondary,
      // $themeColors.info,
      // $themeColors.danger,
      if (value <= 11) {
        return $themeColors.danger;
      } else if (value > 11 && value <= 17) {
        return $themeColors.warning;
      } else if (value > 17 && value <= 35) {
        return $themeColors.success;
      } else if (value > 35 && value <= 74) {
        return $themeColors.info;
      } else if (value > 74 && value <= 100) {
        return $themeColors.primary;
      } else {
        return $themeColors.primary;
      }
    },
  },
};
</script>
<style scoped>
tr{
  margin:15px !important
}
.dark-card p{
  /* color: #D3D3D3; */
  font-size:1rem;
}
.usage-text{
  /* color: #D3D3D3; */
  font-size:1rem;  
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
</style>