<template>
  <div class="d-flex align-items-center justify-content-between w-100 mt-1">
    <div class="d-flex align-items-center justify-content-start" style="width: 65%">
      <b-form-checkbox
        @change="handleSelectionValueChange"
        v-model="selectedValue"
        :value="true"
        :unchecked-value="false"
        class="custom-control-primary"
      >
      </b-form-checkbox>

      <div class="d-flex align-items-center justify-content-start w-100">
        <div class="d-flex align-items-center justify-content-start" style="width: 80px">
          <b-badge variant="danger" class="mx-25">{{ data.control_no }}</b-badge>
        </div>

        <div class="rounded border py-50 px-1 w-100" style="min-width: 515px">
          {{ data.name }}
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-end ml-1">
      <b-button
        v-if="data.applicability_status && data.applicability_status == 1"
        class="mr-50"
        size="sm"
        @click="
          () => {
            handleMarkAsNotApplicable(data.standard_assessment_control_id);
          }
        "
        variant="warning"
        >Mark as Not Applicable</b-button
      >
      <b-button
        v-else
        class="mr-50"
        size="sm"
        @click="
          () => {
            handleMarkAsApplicable(data.standard_assessment_control_id);
          }
        "
        variant="success"
        >Mark as Applicable</b-button
      >
      <!-- <b-dropdown style="width: 180px" text="Select" variant="outline-dark">
        <b-dropdown-item>Implemented</b-dropdown-item>
        <b-dropdown-item>Not Implemented</b-dropdown-item>
        <b-dropdown-item>Planned</b-dropdown-item>
        <b-dropdown-item>Partially Implemented</b-dropdown-item>
      </b-dropdown> -->
      <b-form-select
        size="sm"
        v-model="selectedAction"
        :options="actionOptions"
        @change="handleActionValueChange"
        style="width: 150px"
      />
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    BFormCheckbox,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormSelect,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },

    handleMarkAsNotApplicable: {
      type: Function,
      required: true,
    },
    handleMarkAsApplicable: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedValue: false,
      selectedAction: null,

      actionOptions: [
        { value: 1, text: "Implemented" },
        { value: 2, text: "Partially Implemented" },
        { value: 4, text: "Planned" },
        { value: 3, text: "Not Implemented" },
      ],
    };
  },

  watch: {
    isSelected(newValue) {
      if (newValue == true) {
        this.selectedValue = newValue;
      }
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      if (this.data.implementation_status && this.data.implementation_status != null) {
        this.selectedAction = Number(this.data.implementation_status);
      }
    },

    handleSelectionValueChange(newValue) {
      this.$emit("on-control-selection-change", {
        value: newValue,
        id: this.data.standard_assessment_control_id,
      });
    },

    handleActionValueChange(newValue) {
      this.$emit("on-control-action-change", {
        value: newValue,
        id: this.data.standard_assessment_control_id,
      });
    },
  },
};
</script>

<style></style>
