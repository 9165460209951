<template>
    <div class="w-100 h-auto">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 48%;"
          >
          <!-- {{ updatedDashboardData }} -->

            <standard-details-card :summaryData="updatedDashboardData.filteredStandard||updatedDashboardData.standard" />
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 50%"
          >
          <enitity-assessment-summary :summaryData="getEntityData(updatedDashboardData)"/>
          <!-- <system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
    
          </div>
          <!-- <div
            class="d-flex align-items-center justify-content-center"
            style="width: 33%"
          >
          <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/>
    
          </div> -->
        </div>
    
        <!-- <div class="w-100 d-flex align-items-center justify-content-center mt-25">
          <h2 class="mb-1">Entity Assessment Overview</h2>
        </div> -->
    
        <!-- <div
        class="card-header d-flex flex-column align-items-center justify-content-center pb-0"
      >
        <h4 class="mb-0">
           Implementation By Status
        </h4>
      </div> -->
        <div class="w-100 d-flex align-items-center justify-content-between" >
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 50%"
          >
          <implementation-by-status :seriesData="getImplementationbyStatusData(updatedDashboardData)"/>
          <!-- <implementation-by-clauses :clausesData="Object.values(updatedDashboardData.controlFamilySummary.clause_control_family_group)" /> -->
    
            <!-- <management-system-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.clause_control_family_group.compliance" /> -->
          </div>
    
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 48%"
          >
          <implementation-by-status-radial :complianceData="updatedDashboardData"/>
          <!-- <implementation-by-status :data="updatedDashboardData.ImplementationByStatus.clause_control_family_group"/> -->

          <!-- <implementation-of-annexture-control-requirements-by-domain :clausesData="Object.values(updatedDashboardData.controlFamilySummary.annex_control_family_group)" /> -->
    
            <!-- <annexure-controls-compliance :complianceData="updatedDashboardData.entityAssessmentSummary.annex_control_family_group.compliance"/> -->
          </div>
         
        </div>
    
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 50%"
          >
          <applicable-non-appicable-radial :complianceData="[updatedDashboardData.total_applicable_controls,updatedDashboardData.total_not_applicable_controls]"/>
          <!-- <overall-control-compliance :complianceData="updatedDashboardData.total_implemented/updatedDashboardData.total_applicable_controls*100"/> -->

            <!-- <implementation-of-management-system-requirements :seriesData="Object.values(updatedDashboardData.controlFamilySummary.clause_control_family_group)" /> -->
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 48%"
          >
          <applicable-non-appicable :seriesData="[updatedDashboardData.total_applicable_controls,updatedDashboardData.total_not_applicable_controls]"/>
          <!-- <overall-control-compliance :complianceData="updatedDashboardData.total_implemented/updatedDashboardData.total_applicable_controls*100"/> -->

            <!-- <implementation-of-management-system-requirements :seriesData="Object.values(updatedDashboardData.controlFamilySummary.clause_control_family_group)" /> -->
          </div>
    
          <!-- <div
            class="d-flex align-items-center justify-content-center"
            style="width: 43%"
          >
            <implementation-of-annexture-control-requirements :seriesData="Object.values(updatedDashboardData.controlFamilySummary.annex_control_family_group)"  />
          </div> -->
        </div>
        <!-- <div class="w-100 d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-center"
            style="width: 100%"
          >
          <dashboard-tables :items="Object.values(updatedDashboardData.control_families_summary||[])" title="Implementation By Family" :fields="[
        { key: 'control_family_no', label: 'Control Family No' },
        { key: 'title', label: 'Title' },
        { key: 'true_controls', label: 'Full Compliance' },
        { key: 'none_controls', label: 'Non Compliance' },
        { key: 'partial_controls', label: 'Partial Compliance' },
        { key: 'compliance', label: 'Compliance' }
        ]"/>
         
          </div>

        </div> -->
      </div>
    </template>
      <script>
      import ApplicableNonAppicable from "@/views/GapAssessment/GapAssessmentDetails/sections/ManagementSummary/sections/ApplicableNonAppicable.vue";
import ApplicableNonAppicableRadial from "@/views/GapAssessment/GapAssessmentDetails/sections/ManagementSummary/sections/ApplicableNonAppicableRadial.vue";
import EnitityAssessmentSummary from "@/views/GapAssessment/GapAssessmentDetails/sections/ManagementSummary/sections/EnitityAssessmentSummary.vue";
      import HieControlCompliance from "@/views/GapAssessment/GapAssessmentDetails/sections/ManagementSummary/sections/HieControlCompliance.vue";
import ImplementationByStatus from "@/views/GapAssessment/GapAssessmentDetails/sections/ManagementSummary/sections/ImplementationByStatus.vue";
import ImplementationByStatusRadial from "@/views/GapAssessment/GapAssessmentDetails/sections/ManagementSummary/sections/implementationByStatusRadial.vue";
      import OverallControlCompliance from "@/views/GapAssessment/GapAssessmentDetails/sections/ManagementSummary/sections/OverallControlCompliance.vue";
import StandardDetailsCard from "@/views/GapAssessment/GapAssessmentDetails/sections/ManagementSummary/sections/StandardDetailsCard.vue";
import DashboardTables from "../components/DashboardTables.vue";
    
      
      export default {
        components: {
          EnitityAssessmentSummary,
          HieControlCompliance,
          OverallControlCompliance,
          StandardDetailsCard,
          ImplementationByStatus,
          ImplementationByStatusRadial,
          ApplicableNonAppicableRadial,
          ApplicableNonAppicable,
          DashboardTables,
   
          },
        props:{
            updatedDashboardData:{
                type:Object,
                required:false
            }
        },
        methods:{
            getImplementationbyStatusData(data){
                return [
                { title: 'Fully Implemented', implementation: data.total_implemented },
                { title: 'Partially Implemented', implementation: data.total_partially_implemented },
                { title: 'Planned', implementation: data.total_planned },
                { title: 'Not Implemented', implementation: data.total_not_implemented },
                ];
            },
            getEntityData(obj){
                if (obj) {
              return Object.keys(obj).reduce((acc, curr) => {
                // if (curr === 'compliance') {
                //   acc[curr] = {
                //     title: 'Overall Implementation',
                //     compliance: obj[curr]
                //   };
                // } else
                 if (curr === 'total_not_implemented') {
                  acc[curr] = {
                    title: 'Non compliant controls',
                    compliance: obj[curr],
                    is_number:true
                  };
                }else if (curr === 'total_planned') {
                  acc[curr] = {
                    title: 'Planned controls',
                    compliance: obj[curr],
                    is_number:true
                  };
                }
                 else if (curr === 'total_partially_implemented') {
                  acc[curr] = {
                    title: 'Partially compliant controls',
                    compliance: obj[curr],
                    is_number:true
                  };
                } else if (curr === 'total_implemented') {
                  acc[curr] = {
                    title: 'Compliant controls',
                    compliance: obj[curr],
                    is_number:true
                  };
                }
                return acc;
              }, {
                "total_applicable_controls":{
                  
                    title: 'Applicable controls',
                    compliance: obj["total_applicable_controls"],
                    is_number:true
                  
                },
                'total_not_applicable_controls':{
                  title: 'Not applicable controls',
                    compliance: obj["total_not_applicable_controls"],
                    is_number:true
                }

              });
            }
            return {};
            }
        }

    
      };
      </script>
      
      <style></style>
