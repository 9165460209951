<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card shadow-none bg-transarent" style="min-height: 70vh">
      <div
        class="card-header d-flex flex-column align-items-start justify-content-start pb-0"
      >
        <h2 class="font-weight-bolder">Review your controls</h2>
        <p>Please review the applicability of your controls.</p>
        <div class="pb-25 pt-0 w-100 row">
          <div class="app-fixed-search col-md-4 col-sm-12">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  v-if="filters.search == null || filters.search == ''"
                  icon="SearchIcon"
                  class="text-muted"
                />
                <feather-icon
                  @click="
                    () => {
                      filters.search = null;
                    }
                  "
                  v-else
                  icon="XIcon"
                  class="text-danger cursor-pointer"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="filters.search"
                placeholder="Search Control Name, Number..."
                debounce="500"
                size="md"
              />
            </b-input-group>
          </div>

          <b-form-select
            :class="{
              highlighted: highlightedFilter == 'selectedAction',
            }"
            v-model="filters.selectedAction"
            :options="actionOptions"
            style="width: 240px"
            class="mr-1"
          />

          <b-form-select
            :class="{
              highlighted: highlightedFilter == 'selectedApplicability',
            }"
            v-model="filters.selectedApplicability"
            :options="applicabilityOptions"
            style="width: 240px"
            class="mr-1"
          />

          <b-button @click="handleFiltersReset" variant="danger">
            <span class="align-middle text-center">
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span class="text-center">Reset</span>
            </span>
          </b-button>
        </div>
      </div>

      <div class="card-body">
        <div
          v-if="filteredControlFamilies && filteredControlFamilies.length > 0"
          class="d-flex flex-column align-items-start justify-content-start"
          style="overflow-y: auto"
        >
          <template v-for="(family, i) in filteredControlFamilies">
            <control-family-with-controls
              :key="family._id"
              :count="i + 1"
              :name="family.name"
              :description="family.description"
              :controls="family.controls"
              :reloadParent="load"
            />
          </template>
        </div>
        <template v-else>
          <no-data-found />
        </template>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ControlFamilyWithControls from "./sections/ControlFamilyWithControls.vue";
import {
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import ReviewControlsMixins from "@/mixins/ReviewControlsMixins";
import debounce from "lodash/debounce";
import NoDataFound from "./components/NoDataFound.vue";

export default {
  directives: {
    highlight: {
      bind(el, binding) {
        if (binding.value) {
          el.classList.add("highlighted"); // Apply the highlight when the directive is bound
        }
      },
    },
  },
  mixins: [ResponseMixins, ReviewControlsMixins],
  components: {
    ControlFamilyWithControls,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormSelect,
    BButton,
    NoDataFound,
  },

  data() {
    return {
      controlFamilies: [],
      showOverlay: false,
      highlightedFilter: null,
      filters: {
        search: null,
        selectedAction: null,
        selectedApplicability: null,
      },
      actionOptions: [
        { value: null, text: "Filter by Implementation" },
        { value: 1, text: "Implemented" },
        { value: 2, text: "Partially Implemented" },
        { value: 4, text: "Planned" },
        { value: 3, text: "Not Implemented" },
      ],
      applicabilityOptions: [
        { value: null, text: "Filter by Applicability" },
        { value: 1, text: "Applicable" },
        { value: 2, text: "Not Applicable" },
      ],
    };
  },
  props:{
    standard_id:{
      type:String,
      required:false,
      default:null
    }
  },


  computed: {
    filteredControlFamilies() {
      let newControlFamilies = [...this.controlFamilies];
      newControlFamilies = newControlFamilies.filter(
        (cf) => cf.controls && cf.controls.length
      );

      return newControlFamilies
        .map((cf) => {
          let filteredControls = [...cf.controls];

          if (this.filters.search) {
            const searchTerm = this.filters.search.toLowerCase();
            filteredControls = filteredControls.filter(
              (control) =>
                control.name.toLowerCase().includes(searchTerm) ||
                control.description.toLowerCase().includes(searchTerm) ||
                control.control_no.includes(searchTerm)
            );
          }

          if (this.filters.selectedAction) {
            filteredControls = filteredControls.filter(
              (control) =>
                control.implementation_status == this.filters.selectedAction
            );
          }

          if (this.filters.selectedApplicability) {
            filteredControls = filteredControls.filter(
              (control) =>
                control.applicability_status ==
                this.filters.selectedApplicability
            );
          }

          return {
            ...cf,
            controls: filteredControls,
          };
        })
        .filter((cf) => cf.controls.length);
    },
  },

  watch: {
    "filters.search": debounce(function (newVal) {
      this.filters.search = newVal;
    }, 500),
    standard_id(){
      this.load()
    }

  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.filters = this.getFilters();
      this.getStandardAssessmentsAndSetData({});
    },

    getStandardAssessmentsAndSetData() {
      this.setOverlay(true);
      this.getStandardAssessments({mapped_standard_id:this.standard_id})
        .then((res) => {
          if (
            res.data.data.controlFamilies &&
            res.data.data.controlFamilies != null
          ) {
            this.controlFamilies = res.data.data.controlFamilies;
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.setOverlay(false);
        });
    },

    getFilters() {
      const queryParams = this.$route.query;
      const newFilters = { ...this.filters };

      if ("selectedAction" in queryParams) {
        newFilters["selectedAction"] = Number(queryParams["selectedAction"]);
        this.setAnimation("selectedAction");
      }

      if ("selectedApplicability" in queryParams) {
        newFilters["selectedApplicability"] = Number(
          queryParams["selectedApplicability"]
        );
        this.setAnimation("selectedApplicability");
      }

      this.$router.replace({ query: null });
      return newFilters;
    },

    setAnimation(filter) {
      this.highlightedFilter = filter;
      setTimeout(() => {
        this.highlightedFilter = null;
      }, 1000);
    },

    setOverlay(value) {
      this.showOverlay = value;
    },

    handleFiltersReset() {
      this.filters = {
        search: null,
        selectedAction: null,
        selectedApplicability: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table th {
  text-transform: none;
}

.filters_section {
  background-color: #f2f0f7;
}

.dark-layout {
  .filters_section {
    background-color: #161d31;
  }
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    background-color: transparent;
  }
}
</style>

<style scoped>
.highlighted {
  animation: breathingEffect 1s infinite ease-in-out; /* Apply breathing animation for 7 seconds */
}

@keyframes breathingEffect {
  0% {
    border: 1px solid #00cfe8;
    box-shadow: 0 0 10px 10px #00d1e883;
  }
  50% {
    border: 1px solid transparent;
    box-shadow: none;
  }
  100% {
    border: 1px solid #00cfe8;
    box-shadow: 0 0 10px 10px #00d1e883;
  }
}
</style>
