<template>
  <e-charts
    ref="barChart"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
    @click="handleChartClick"
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/bar";
import theme from "@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
    yAxisMax: {
      type: Number,
      default: null,
    },
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          left: 0,
          top: 2,
          ...this.optionData.legend,
        },
        grid: this.optionData.grid,
        color: [ '#91CC75', '#EE6666','#5470C6', '#FAC858', '#73C0DE', '#3BA272', '#FC8452', '#9A60B4', '#EA7CCC'], // Custom color palette
        xAxis: {
          ...this.optionData.yAxis,
          axisLabel: {
            ...this.optionData.yAxis.axisLabel,
            formatter: function (value) {
              return value.length > 10 ? value.slice(0, 10) + '...' : value;
            },
            width: 60,
            overflow: 'truncate',
          },
        },
        yAxis: {
          ...this.optionData.xAxis,
          max: this.yAxisMax, // Set the maximum value for the y-axis
        },
        series: this.optionData.series.map((serie) => ({
          type: 'bar',
          barWidth: '40%', // Optional: set bar width
          ...serie,
        })),
      };
    },
  },
  data() {
    return {};
  },
  watch: {
    yAxisMax(newVal) {
      this.option.yAxis.max = newVal; // Update the maximum value for the y-axis
      this.updateChart();
    },
  },
  methods: {
    updateChart() {
      this.$refs.barChart.setOption(this.option);
    },
    handleChartClick(event) {
      this.$emit("chart-click", event.name);
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
