<template>
  <div class="w-100 h-auto">
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card bg-transparent shadow-none">
        <div
          class="card-header d-flex flex-column align-items-start justify-content-start mb-50 pb-0 px-25"
        >
          <h4 class="font-weight-bold" style="max-width: 780px">
            <span class="mr-25">{{ count }}. </span><span>{{ name }}</span>
          </h4>
          <p class="mb-0" style="max-width: 780px">
            {{ description }}
          </p>
        </div>

        <div class="card-body px-25">
          <div class="w-100 d-flex align-items-center justify-content-start">
            <b-form-checkbox
              v-model="allControlsSelected"
              :value="true"
              class="custom-control-primary mr-1"
              @change="handleAllControlsSelectionChange"
            >
            </b-form-checkbox>
            <!-- <b-button
              size="sm"
              @click="handleMarkSelectedAsNotApplicableClick"
              :disabled="disableApplicableButton"
              variant="primary"
              >Mark Selected as Not Applicable</b-button
            > -->

            <b-dropdown
              text="Mark Selected as"
              variant="info"
              size="sm"
              :disabled="disableApplicableButton"
            >
              <b-dropdown-item @click="handleMarkSelectedAsApplicableClick"
                >Applicable</b-dropdown-item
              >
              <b-dropdown-item @click="handleMarkSelectedAsNotApplicableClick"
                >Not Applicable</b-dropdown-item
              >
            </b-dropdown>
          </div>

          <div class="d-flex flex-column align-items-start justify-content-start w-100">
            <template v-for="control in controls">
              <control-item
                v-if="control.type == 'Control'"
                :key="control.standard_assessment_control_id"
                :data="control"
                :isSelected="allControlsSelected"
                @on-control-selection-change="handleControlSelectionChange"
                @on-control-action-change="handleControlActionChange"
                :handleMarkAsNotApplicable="handleMarkAsNotApplicable"
                :handleMarkAsApplicable="handleMarkAsApplicable"
              />
            </template>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BBadge,
  BButton,
  BOverlay,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import ControlItem from "../components/ControlItem.vue";
import ResponseMixins from '@/mixins/ResponseMixins';
import ReviewControlsMixins from '@/mixins/ReviewControlsMixins';

export default {
  mixins: [ResponseMixins, ReviewControlsMixins],
  components: {
    BFormCheckbox,
    BBadge,
    BButton,
    ControlItem,
    BOverlay,
    BDropdown,
    BDropdownItem,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    count: {
      type: Number,
      required: true,
    },
    controls: {
      type: Array,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      allControlsSelected: false,
      selectedControls: [],
      showOverlay: false,
    };
  },

  computed: {
    disableApplicableButton() {
      if (this.selectedControls.length > 0) {
        return false;
      }
      return true;
    },
  },

  watch: {
    selectedControls(newValue) {
      if (newValue.length != this.controls.length) {
        this.allControlsSelected = false;
      }
    },
  },

  methods: {
    updateStandardAssessmentControlAndSetData(data) {
      this.setOverlay(true);
      this.updateStandardAssessmentControl(data)
        .then((res) => {
          // console.log(res);
          this.handleResponse(res);
          this.reloadParent();
          this.resetState();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.setOverlay(false);
        });
    },

    handleControlActionChange(data) {
      const newParams = {
        implementation_status: data.value,
        standard_assessment_control_ids: [data.id],
      };

      this.updateStandardAssessmentControlAndSetData(newParams);
    },

    handleMarkSelectedAsApplicableClick() {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to mark all the selected Controls as Applicable.",
          {
            title: "Warning",
            size: "sm",
            okVariant: "success",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
            headerBgVariant: "light-warning",
          }
        )
        .then((value) => {
          if (value === true) {
            const newParams = {
              applicability_status: 1,
              standard_assessment_control_ids: this.selectedControls,
            };

            this.updateStandardAssessmentControlAndSetData(newParams);
          }
        });
    },

    handleMarkSelectedAsNotApplicableClick() {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to mark all the selected Controls as Not Applicable.",
          {
            title: "Warning",
            size: "sm",
            okVariant: "success",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
            headerBgVariant: "light-warning",
          }
        )
        .then((value) => {
          if (value === true) {
            const newParams = {
              applicability_status: 2,
              standard_assessment_control_ids: this.selectedControls,
            };

            this.updateStandardAssessmentControlAndSetData(newParams);
          }
        });
    },

    handleMarkAsApplicable(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to mark this Control as Applicable.",
          {
            title: "Warning",
            size: "sm",
            okVariant: "success",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
            headerBgVariant: "light-warning",
          }
        )
        .then((value) => {
          if (value === true) {
            const newParams = {
              applicability_status: 1,
              standard_assessment_control_ids: [id],
            };

            this.updateStandardAssessmentControlAndSetData(newParams);
          }
        });
    },

    handleMarkAsNotApplicable(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to mark this Control as Not Applicable.",
          {
            title: "Warning",
            size: "sm",
            okVariant: "success",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
            headerBgVariant: "light-warning",
          }
        )
        .then((value) => {
          if (value === true) {
            const newParams = {
              applicability_status: 2,
              standard_assessment_control_ids: [id],
            };

            this.updateStandardAssessmentControlAndSetData(newParams);
          }
        });
    },

    handleControlSelectionChange(data) {
      if (data.value === true) {
        let newSelected = this.selectedControls.filter((s) => s != data.id);
        newSelected.push(data.id);
        this.selectedControls = newSelected;
      } else {
        let newSelected = this.selectedControls.filter((s) => s != data.id);
        this.selectedControls = newSelected;
      }
    },

    handleAllControlsSelectionChange(newValue) {
      if (newValue === true) {
        this.selectedControls = this.controls.map(
          (c) => c.standard_assessment_control_id
        );
      }
      // else if (
      //   newValue === false &&
      //   this.selectedControls.length == this.controls.length
      // ) {
      //   this.selectedControls = [];
      // }
    },

    setOverlay(value) {
      this.showOverlay = value;
    },

    resetState() {
      this.allControlsSelected = false;
      this.selectedControls = [];
    },
  },
};
</script>

<style></style>
